<template>
  <div>
    <b-modal id="modal-slot" @hide="closeModal" ref="modal-slot" hide-header hide-footer class="app-modal" header-class="text-right" dialog-class="modal-dialog-centered" content-class="modal-dialog-centered" closeheader="none" >
       <template v-slot:modal-header="{ close }" class="close-wrapper">
      <div class="close-wrapper" v-if="false">
        <div class="close-btn" @click="close()"><i class="fas fa-times"></i></div>
      </div>
    </template>
    <slot></slot>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'ModalSlot',
  props: {
    title: String,
    content: String,
    acceptButton: Boolean,
    acceptButtonContent: String,
    closeButton: Boolean,
    closeButtonContent: String
  },
  data () {
    return {
    }
  },
  methods: {
    closeModal () {
      this.$emit('close-modal', false)
      this.$refs['modal-slot'].hide()
    },
    showModal () {
      this.$refs['modal-slot'].show()
    }
  },
  mounted () {
    this.$refs['modal-slot'].show()
  }
}
</script>
<style lang="scss" scoped>
  .close-wrapper {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .close-btn {
    font-size: 30px;
    cursor: pointer;
  }
</style>
