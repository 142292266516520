<template>
  <div>
    <TopMenuSubheadline :title="title"/>
    <div class="app-content-box bg-gray">
      <div class="wrapper">
        <div class="container mt-4">
          <div class="books-list-masonry">
            <div v-for="(item, $index) in list" :key="$index">
              <router-link :to="{name: 'BooksDetails', params: {id: item.id}}">
                <div class="book-card">
                  <div class="book-cover-container">
                    <div class="book-cover">
                      <img
                        :src="item.image ? (coverUrl + item.image) : (coverUrl + 'no_cover.png')"
                      />
                    </div>
                    <div class="bottom-plus-sign" @click.prevent="addBook(item.id)"><i class="fas fa-plus-circle"></i></div>
                  </div>
                  <div class="mb-3">
                    <a href="" class="book-title">{{ item.autor }}</a>
                    <span class="book-info">{{ item.tytul }}</span>
                  </div>
              </div>
              </router-link>
              <ModalSlot title="Dodaj książkę" v-if="isPopupShown && checkId === item.id" @close-modal="closeModal">
                <div class="text-center mb-2 modal-main-title">Dodaj książkę</div>
                <div class="modal-wrapper">
                  <div class="growing-slide-cover wrapper-cover w-100">
                    <img :src="item.image ? (coverUrl + item.image) : (coverUrl + 'no_cover.png')" class="slide-cover-image"/>
                    </div>
                    <div class="book-heading-big text-center mt-3">{{ item.autor}}</div>
                    <div class="book-heading-small text-center mt-2">{{ item.tytul }}</div>
                </div>
                <router-link :to="{name: 'BorrowBook', params: {id: item.id}}"><button class="btn-black-border w-100 mt-3 mb-1">Dodaj na półkę</button></router-link>
                <button class="btn-black-border w-100 mt-3 mb-1" @click="saveToLater(item.id)">Zapisz na później</button>
                <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
              </ModalSlot>
          </div>
          </div>
          <infinite-loading @infinite="getBooksInTag">
            <div slot="spinner" class="d-flex align-items-center justify-content-center w-100">
              <img src="../assets/loaders/loader_on_overlay.gif" alt="loader" />
            </div>
          </infinite-loading>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import ModalSlot from '../components/ModalSlot'
export default {
  name: 'TagsDetails',
  props: {
    id: [String, Number]
  },
  components: {
    TopMenuSubheadline,
    ModalSlot
  },
  data () {
    return {
      page: 1,
      isLoading: false,
      list: [],
      isPopupShown: false,
      checkId: 0,
      title: ''
    }
  },
  methods: {
    addBook (id) {
      this.checkId = id
      this.isPopupShown = true
    },
    closeModal () {
      this.isPopupShown = false
      this.checkId = 0
    },
    saveToLater (id) {
      const getData = 'status=later&own=1&borrowed=0'
      this.$https('book/' + id + '/add/' + 0, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.closeModal()
      })
        .catch(error => {
          console.log('', error)
        })
    },
    getBooksInTag ($state) {
      // const getData = `page=${this.page}&genres=${this.id}`
      this.$https('/category/' + this.id + '?page=' + this.page, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(({ data }) => {
        this.title = 'wszystko ' + data.response[0].label
        if (data.response[0].items.length) {
          this.page += 1
          this.list.push(...data.response[0].items)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
  .wrapper {
    padding-top: 20px;
    padding-bottom: 50px;
  }
    .bottom-plus-sign {
    position: absolute;
    bottom: 20px;
    left: 48%;
    z-index: 2;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    transform: translate(-12px, 12px);
    z-index: 5;
    &::before {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      z-index: 3;
      width: 24px;
      height: 24px;
      font-size: 24px;
      line-height: 24px;
      color: #000;
      content: '\f055';
      background-color: #fff;
      border-radius: 50%;
      font-weight: 900;
      font-family: 'Font Awesome 5 Free';
      transform: translate(-50%, -50%);
    }
    }
  .wrapper-cover {
    display: flex;
    justify-content: center;

    img {
      max-height: 270px;
      max-width: 210px;
    }
  }
</style>
