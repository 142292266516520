<template>
<div class="top-menu-visible top-menu-dark">
  <div class="app-title sub-title">
    <span class="app-title-text">Bookme</span>
    <h2 v-if="title">{{title}}</h2>
  </div>
</div>
</template>
<script>
export default {
  name: 'TopMenuSubheadline',
  props: {
    title: String
  }
}
</script>
<style lang="scss" scoped>

</style>
